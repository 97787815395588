import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import track from "../components/track"
import heroHome from "../images/hero-home.png"
import heroHomeLarge from "../images/hero-home-large.png"
import heroHomeMobile from "../images/hero-home-mobile.png"
import homeButton from "../images/homepage-button-patient.png"

const IndexPage = () => {

  const pageTitle = 'MOVANTIK® (naloxegol): Official Patient Website';
  const pageDescription = 'Find out about opioid-induced constipation and the drug, MOVANTIK. Have over-the-counter laxative treatments been unsuccessful? Consider a prescription medicine.';
  const pageName = "Home";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="patient-home" pageClass="home" pageName={pageName}>
      <div className="hero">
        <img src={heroHomeLarge} className="hero-img large" alt="" />
        <img src={heroHome} className="hero-img medium" alt="" />
        <img src={heroHomeMobile} className="hero-img mobile" alt="" />
        <div className="container">
          <div className="headlines">
            <div className="eyebrow">When opioid constipation slows you down,</div>
            <h1>MOVANTIK gets you moving.</h1>
            <div>
                <img src={homeButton} className="home-btn" alt="Number 1 Prescribed Oral Treatment of its kind" />
            </div>
          </div>

        </div>
      </div>
      <div className="container home-main-content">
        <h3>If you’re taking opioids and are constipated, don’t suffer in silence.</h3>
        <div className="home-callouts">
          <div className="home-callout">
            <Link to="/burden-of-oic/" id="patient-home-btn-explore" className="button" onClick={() => track("patient Home page", "button click", "DTC Homepage-Is Movantik Right for You")}>
              <div className="btn-text">Find out if MOVANTIK<br /> <span className="bold">is right for you</span></div>
              <div className="btn-arrow"></div>
            </Link>
          </div>
          <div className="home-callout">
            <Link to="/about/" id="patient-home-btn-explore" className="button" onClick={() => track("patient Home page", "button click", "DTC Homepage-Is Movantik Right for You")}>
              <div className="btn-text"><span className="bold">Same-day relief</span> is possible.<br />Find out how</div>
              <div className="btn-arrow"></div>
            </Link>
          </div>
          <div className="home-callout">
            <Link to="/savings/" id="patient-home-btn-savings" className="button" onClick={() => track("patient Home page", "button click", "DTC Homepage-Save with MOVANTIK")}>
              <div className="btn-text"><span className="bold">Save</span> with MOVANTIK</div>
              <div className="btn-arrow"></div>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="end-matter">
        <div className="footnotes">
          {/* <div className="footnote asterisk">*At least 1 hour before the first meal of the day or 2&nbsp;hours after the meal. Consumption of grapefruit or grapefruit juice during treatment with MOVANTIK is to be avoided.<sup>1</sup></div> */}
          </div>
          <div className="references"><span className="bold">References: 1.</span>&nbsp;MOVANTIK<sup>®</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Data on file. Prescription data. Valinor Pharma, LLC; 2023.</div>
        </div>
      </div>
    </Layout>
  )
}


export default IndexPage
